/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-self-assign */
/* eslint-disable no-case-declarations */
import { coerceArray } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { datadogLogs } from '@datadog/browser-logs';
import { TranslateService } from '@ngx-translate/core';
import { asRoadEventCameraInput, IncidentIndicationExpiry, MainEntityType, RoadEventCamera, UiStore } from '@wc/core';
import { ConfirmationModalType, SoundAlertMode } from '@wc/core/models/enums';
import { UIIncident } from '@wc/core/models/extended.models';
import { SelectOption } from '@wc/features/ui/form-controls/form-models';
import { PermissionsFacadeService } from '@wc/permissions/domain/src';
import {
  EXPIRE_TIME_AFTER_WARNING_SHOW_IN_MIN,
  sortAffectedLanes,
  WAIT_TIME_TO_SHOW_EXPIRE_WARNING_IN_MIN,
} from '@wc/utils';
import { IncidentStoreEntity } from '@wc/wc-models/src';
import { ConfirmModalService, ToastrAlertsService } from '@wc/wc-ui/src';
import { CompleteRejectFormComponent } from '@wc/wc-ui/src/components/complete-reject-form/complete-reject-form.component';
import { FormFieldOption } from '@wc/wc-ui/src/lib/base';
import { IndividualConfig } from 'ngx-toastr';
import {
  EMPTY,
  merge,
  MonoTypeOperatorFunction,
  Observable,
  of,
  pipe,
  Subject,
  Subscription,
  throwError,
  timer,
} from 'rxjs';
import { catchError, concatAll, filter, finalize, map, mergeAll, skip, switchMap, take, tap } from 'rxjs/operators';
import {
  ActivityLogAggregated,
  AddressInput,
  AssociatedCamera,
  AssociatedCameraInput,
  CreateIncidentMutationInput,
  EndIncidentInput,
  FindIncidentsInPointRadiusInput,
  Incident,
  IncidentAdditionalInfoInput,
  IncidentConfiguration,
  IncidentEndReason,
  IncidentIndicationType,
  IncidentInput,
  IncidentMitigationInput,
  IncidentReportSourceInput,
  IncidentStatus,
  IncidentType,
  IncidentUnitInput,
  IncidentView,
  InvolvedVehicleInput,
  LayerType,
  RelatedIncident,
  SegmentDetails,
  UnitResponse,
} from '../../../../core/models/gql.models';
import { GeoService, SplitIOService } from '../../../../core/services';
import { CustomRxOperatorsService } from '../../../../core/services/custom-rx-operators.service';
import { AccountStore } from '../../../../core/stores/account.store';
import { EntitiesServiceV2 } from '../services/entities.service';
import { CamerasQuery } from '../stores/entities/cameras/cameras.query';
import { EntitiesQuery } from '../stores/entities/entities.query';
import { IncidentsQuery } from '../stores/entities/incidents/incidents.query';
import { CollaborationService } from './collaboration.service';
import { HeapAnalyticsService } from './heap-analytics.service';
import { IncidentsApiService } from './incidents-api.service';
import { ForcedMitigationEndIncident, IncidentsUtilsService } from './incidents-utils.service';
import { LiveMapService } from './live-map.service';
import { LoaderService } from './loader.service';
import { LocalStorageKeys, LocalStorageService } from './local-storage.service';
import { AppSounds, SoundsService } from './sounds.service';
import { UploadService, UrlUploadEntityEnum } from './upload.service';

export type LastActiveEditIncident = {
  incidentId: number;
  timestamp: Date;
};

@Injectable({
  providedIn: 'root',
})
export class IncidentsService {
  private readonly _onIncidentEdited = new Subject<number>();
  private set onIncidentEdited(incidentId: number) {
    this._onIncidentEdited.next(incidentId);
  }

  private newSoundAlertEntities = new Subject<string[]>();
  private _currentPendingDelete: number[] = [];
  private _cachedInprogressIncidents!: Record<string, boolean>;
  private _cachedNeedActionIncidentIds!: Record<string, boolean>;
  private _cachedInprogressIncidentsIdsWhoHadSound: Record<string, boolean> = {};
  private _cachedWrongWayAlertIncidentIds: Record<string, boolean> = {};
  private _cachedAllIncidentIds: Record<string, boolean> = {};
  private _cachedNeedActionSeenIncidentsIds: Record<string, boolean> = {};

  readonly newSoundAlertEntities$ = this.newSoundAlertEntities.asObservable();
  readonly onIncidentEdited$ = this._onIncidentEdited.asObservable();

  wrongWayAlertPopupOpen = false;
  wrongWayAlertsActive = false;
  concatPreventEditTime?: Subscription;
  incidentSubtypes: SelectOption[] = [];
  segmentDetails: SegmentDetails = {} as SegmentDetails; // can be deleted after lane-list will be copied from features to wc-ui
  interactedIncidentId$ = new Subject<number>();
  indicationExpiryListChangeId$ = new Subject<number>();
  indicationExpiryList: IncidentIndicationExpiry[] =
    this.localStorageService.get(LocalStorageKeys.IncidentIndicationsIds) || [];
  // addressContinueEditMode = false; // not sure. will be deleted after QA tests address component
  hasInsight = false;

  get cachedNeedActionIncidentIds() {
    return this._cachedNeedActionIncidentIds;
  }

  get cachedInprogressIncidentIdsWhoHadSound(): Record<string, boolean> {
    return this._cachedInprogressIncidentsIdsWhoHadSound;
  }

  get cachedInprogressIncidents(): Record<string, boolean> {
    return this._cachedInprogressIncidents;
  }

  get editCollaboration() {
    return this.collaborationService.collaborations.edit;
  }

  get viewCollaboration() {
    return this.collaborationService.collaborations.view;
  }
  /**
   * @deprecated used to support old api from incident.store.ts. use incidentsUtilsService directly
   */
  get incidentStatus(): Array<SelectOption> {
    return this.incidentsUtilsService.incidentStatusOptions;
  }

  /**
   * @deprecated used to support old api from incident.store.ts. use incidentsUtilsService directly
   */
  get incidentTypes(): Array<SelectOption> {
    return this.incidentsUtilsService.incidentTypesOptions;
  }
  /**
   * @deprecated used to support old api from incident.store.ts. use incidentsUtilsService directly
   */
  getFormFieldData() {
    return this.incidentsUtilsService.getFormFieldData();
  }

  /**
   * @deprecated used to support old api from incident.store.ts. use incidentsUtilsService directly
   */
  get severityOptions(): Array<SelectOption> {
    return this.incidentsUtilsService.incidentSeverityOptions;
  }

  /**
   * @deprecated used to support old api from incident.store.ts. use incidentsUtilsService directly
   */
  get roadTypesOptions(): Array<SelectOption> {
    return this.incidentsUtilsService.incidentRoadTypesOptions;
  }

  /**
   * @deprecated used to support old api from incident.store.ts. use incidentsUtilsService directly
   */
  get laneTypesOptions(): Array<SelectOption> {
    return this.incidentsUtilsService.incidentLaneTypesOptions;
  }

  /**
   * @deprecated used to support old api from incident.store.ts. use incidentsUtilsService directly
   */
  get laneDirectionsOptions(): Array<SelectOption> {
    return this.incidentsUtilsService.incidentLaneDirectionsOptions;
  }

  /**
   * @deprecated used to support old api from incident.store.ts. use incidentsUtilsService directly
   */
  get orientationOptions(): Array<SelectOption> {
    return this.incidentsUtilsService.incidentOrientationOptions;
  }

  /**
   * @deprecated used to support old api from incident.store.ts. use incidentsUtilsService directly
   */
  get attributesOptions(): Array<SelectOption> {
    return this.incidentsUtilsService.incidentAttributesOptions;
  }

  /**
   * @deprecated used to support old api from incident.store.ts. use incidentsUtilsService directly
   */
  get notificationDestinationOptions(): Array<FormFieldOption> {
    return this.incidentsUtilsService.incidentNotificationDestinationOptions;
  }

  /**
   * @deprecated used to support old api from incident.store.ts. use incidentsUtilsService directly
   */
  isIncidentFieldMandatory(filedName: keyof IncidentConfiguration) {
    return this.incidentsUtilsService.isIncidentFieldMandatory(filedName);
  }

  getActivityLog(id: number): Observable<ActivityLogAggregated[]> {
    return this.incidentsApiService.incidentActivityLog(id);
  }

  constructor(
    private incidentsApiService: IncidentsApiService,
    private dialog: MatDialog,
    private uiStore: UiStore,
    private permissionsFacadeService: PermissionsFacadeService,
    private liveMapService: LiveMapService,
    private uploadService: UploadService,
    private accountStore: AccountStore,
    private translateService: TranslateService,
    private alertService: ToastrAlertsService,
    private localStorageService: LocalStorageService,
    private geoService: GeoService,
    private sounds: SoundsService,
    private confirmService: ConfirmModalService,
    private router: Router,
    private entitiesService: EntitiesServiceV2,
    private entitiesQuery: EntitiesQuery,
    private incidentsQuery: IncidentsQuery,
    private customRxjsService: CustomRxOperatorsService,
    private camerasQuery: CamerasQuery,
    private collaborationService: CollaborationService,
    private splitIoService: SplitIOService,
    private incidentsUtilsService: IncidentsUtilsService,
    private loaderService: LoaderService,
    private heapService: HeapAnalyticsService
  ) {
    this.wrongWayAlertsActive = this.permissionsFacadeService.hasPermission('WRONG_WAY_ALERT:READ');
    this.wrongWayAlertsInitializer();
    const visibleNeedActionIncidents$ = this.incidentsQuery.visibleNeedActionIncidents.pipe(
      filter(incidents => incidents.length > 0),
      tap(incidents => this.handleNeedActionIncidentsToPlaySoundAlert(incidents))
    );

    this.entitiesQuery.select().subscribe({
      next: () => this.handleIncidentIndicationList(),
    });

    this.incidentsQuery.allUnconfirmedIncidents$
      .pipe(skip(1))
      .subscribe(incidents => this.needActionNewEventsHandle(incidents.map(inc => inc.id)));

    this.incidentsQuery
      .selectAll()
      .pipe(
        filter(incidents => incidents.length > 0),
        this.customRxjsService.tapOnce(incidents => {
          const { needActionList, inProgressList } = incidents.reduce<{
            needActionList: IncidentStoreEntity[];
            inProgressList: IncidentStoreEntity[];
          }>(
            (acc, inc) => {
              if (inc.status === IncidentStatus.Unconfirmed) acc.needActionList.push(inc);
              if (inc.status === IncidentStatus.Confirmed) acc.inProgressList.push(inc);
              return acc;
            },
            {
              needActionList: [],
              inProgressList: [],
            }
          );
          this.cacheIncidents(needActionList, (this._cachedNeedActionIncidentIds = {}));
          this.cacheIncidents(inProgressList, (this._cachedInprogressIncidents = {}));
        }),
        switchMap(() => merge([visibleNeedActionIncidents$]).pipe(mergeAll()))
      )
      .subscribe();

    /** @description incident indications should be expired once the incident is clicked */
    this.interactedIncidentId$.subscribe(incidentId => {
      this.handleExpireIncidentIndication(incidentId);
    });
  }

  private needActionNewEventsHandle(currentNeedActionsIds: number[]) {
    const viewedIds = this.localStorageService.get(LocalStorageKeys.NeedActionSeenIncidentsIds) || [];
    const newIds = currentNeedActionsIds.filter(id => !viewedIds.includes(id));
    newIds.forEach(id => {
      const incident = this.incidentsQuery.getEntity(id);

      if (!incident?.priorityScore || !incident.priorityScore.level || !incident.insights?.priorityScore) {
        datadogLogs.logger.info('heap priorityScore missing on new event', {
          incident: incident,
          diffTimeStamp: this.entitiesService.lastUpdatedAt,
        });
      }

      this.heapService.trackUserSpecificAction('bi-analytics-incident-list-new-incident-created', {
        id: id,
        incidentPriority: incident?.priorityScore?.level || incident?.insights?.priorityScore,
        incidentType: incident?.type,
      });
    });
    this.localStorageService.set(LocalStorageKeys.NeedActionSeenIncidentsIds, currentNeedActionsIds);
  }

  startCompleteReject(incident: Incident, status: IncidentStatus, closeIncident = true, isFromView: boolean) {
    const unitResponses = [UnitResponse.OnScene, UnitResponse.UnknownUnitStatus, UnitResponse.EnRoute]; // allowed types for feature
    const associatedUnitsHaveMatchingUnitResponse = incident.associatedUnits.some(
      unit => unit && unitResponses.includes(unit.unitResponse)
    );

    if (
      associatedUnitsHaveMatchingUnitResponse &&
      [IncidentStatus.Completed, IncidentStatus.Rejected].includes(status)
    ) {
      this.confirmService.showConfirmDialog(ConfirmationModalType.CompleteHasUnits, status, confirmed => {
        if (confirmed) {
          this.openCompleteRejectFormDialog(incident, status, closeIncident, isFromView);
        }
      });
    } else this.openCompleteRejectFormDialog(incident, status, closeIncident, isFromView);
  }

  closeIncident() {
    this.removeLocalStorageUserEditTime();
    this.clearIncidentSelection();
    this.loaderService.reset();
    this.router.navigate(['live-map']);
  }

  getIncidentsCompletedList() {
    this.incidentsApiService
      .findLatestIncidents()
      .pipe(
        map(incidents =>
          incidents.map(incident => ({ ...incident, show: incident.type === IncidentType.Unidentified ? true : false }))
        ),
        tap(incidents =>
          this.entitiesService.emitNewEntitiesDiff({
            modified: {
              INCIDENT: incidents,
            },
          })
        )
      )
      .subscribe(() => {
        setTimeout(() => {
          this.updateIncidentLoader(false);
        }, 100);

        () => this.updateIncidentLoader(false);
      });
  }

  clearIncidentSelection() {
    this.liveMapService.unselectFeature();
    datadogLogs.removeLoggerGlobalContext('wc_incident_details');
  }

  removeLocalStorageUserEditTime() {
    this.localStorageService.remove('userLastEdit');
  }

  get subtypeOptions() {
    return this.incidentSubtypes;
  }

  updateIncidentSubtypes(incidentType: IncidentType) {
    this.incidentSubtypes = this.accountStore.incidentSubTypeStructure[incidentType] || [];
  }

  setIncidentStatus(incident: Incident, status: IncidentStatus, mergedTargetId?: number): Observable<any> {
    this.updateIncidentLoader(true);
    const done = () => {
      this.entitiesService.emitNewEntitiesDiff({
        modified: {
          INCIDENT: [{ id: incident.id, status }],
        },
      });
      this.updateIncidentLoader(false);
    };

    const id: any = Number(incident.id) || incident.id;
    switch (status) {
      case IncidentStatus.Completed:
        const completeEndIncidentInput: EndIncidentInput = {
          endReason: incident.endReason as IncidentEndReason,
          endReasonComment: incident.endReasonComment,
          incidentId: incident.id,
          targetIncidentId: mergedTargetId,
          hazardRemains: incident.hazardRemains,
        };
        return this.incidentsApiService.completeIncident(completeEndIncidentInput).pipe(
          tap(() => done()),
          finalize(() => this.updateIncidentLoader(false))
        );
      case IncidentStatus.Confirmed:
        return this.incidentsApiService.confirmIIncident({ id }).pipe(
          tap(() => done()),
          finalize(() => this.updateIncidentLoader(false))
        );
      case IncidentStatus.Rejected:
        const rejectEndIncidentInput: EndIncidentInput = {
          endReason: incident.endReason as IncidentEndReason,
          endReasonComment: incident.endReasonComment,
          incidentId: incident.id,
          targetIncidentId: mergedTargetId,
          hazardRemains: incident.hazardRemains,
        };
        return this.incidentsApiService.rejectIncident(rejectEndIncidentInput).pipe(
          tap(() => done()),
          finalize(() => this.updateIncidentLoader(false))
        );
      default:
        return throwError('No incident status was provided');
    }
  }

  selectIncident(incident: Partial<Incident>, zoomOn: boolean = true) {
    if (zoomOn)
      this.liveMapService.setMapCenter(incident.location.coordinates, {
        zoomLevel: 15,
        padding: [0, -560, 0, 0],
        duration: 1000,
      });
    if (incident.id) this.liveMapService.selectFeature(LayerType.Incident, incident.id);
    datadogLogs.removeLoggerGlobalContext('wc_incident_details');
    datadogLogs.addLoggerGlobalContext('wc_incident_details', JSON.stringify(incident));
  }

  undoCompleteIncident(incident: Incident) {
    this.updateIncidentLoader(true);
    this.incidentsApiService.undoCompleteIncident({ id: incident.id }).subscribe(
      res => {
        if (res === true) {
          this.entitiesService.emitNewEntitiesDiff({
            modified: {
              INCIDENT: [{ id: incident.id, status: IncidentStatus.Confirmed, show: undefined }],
            },
          });
          this.updateIncidentLoader(false);
          this.showNotification('success', 'notifications.restoreSuccess');
        } else {
          this.showNotification('failure', 'notifications.failedToRestoreIncident');
          this.updateIncidentLoader(false);
          throwError('Failed To Restore Incident');
        }
      },

      () => this.showNotification('failure', 'notifications.failedToRestoreIncident')
    );
  }

  undoRejectIncident(incident) {
    this.updateIncidentLoader(true);
    this.incidentsApiService.undoRejectIncident({ id: incident.id }).subscribe(
      res => {
        if (res === true) {
          this.entitiesService.emitNewEntitiesDiff({
            modified: {
              INCIDENT: [{ id: incident.id, status: IncidentStatus.Confirmed, show: undefined }],
            },
          });
          this.updateIncidentLoader(false);
          this.showNotification('success', 'notifications.restoreSuccess');
        } else {
          this.updateIncidentLoader(false);
          this.showNotification('failure', 'notifications.failedToRestoreIncident');
          throwError('Failed To Restore Incident');
        }
      },
      error => {
        this.updateIncidentLoader(false);
        console.log('Error: ', error);
        this.showNotification('failure', 'notifications.failedToRestoreIncident');
      }
    );
  }

  updateIncidentLanes(updatedIncident: Incident, originalIncident: Incident) {
    const laneInput = this.incidentsUtilsService.createIncidentInputLanes(
      originalIncident,
      updatedIncident as ForcedMitigationEndIncident
    );
    const incidentInput: IncidentInput = {
      incidentId: updatedIncident.id,
      lanes: laneInput,
      updateIncidentInput: {
        incidentId: updatedIncident.id,
      },
    };

    return this.incidentsApiService.updateIncidentOneTimeCall(incidentInput);
  }

  updateIncident(updatedIncident: Incident & IncidentInput, originalIncident: Incident, showLoader = true) {
    const _incidentInput = this.incidentsUtilsService.createIncidentInput(updatedIncident, originalIncident);
    this.updateIncidentLoader(showLoader);
    return this.incidentsApiService.updateIncidentOneTime(_incidentInput).pipe(
      tap((incident: Incident) => {
        incident.affectedLanes = sortAffectedLanes(coerceArray(incident.affectedLanes));
        this.updateIncidentLoader(false);
        if (!incident) return;
        incident.type = incident.type || IncidentType.Unidentified;
        // removed interaction so highlight shows
        this.onIncidentEdited = incident.id;
        this.uploadFiles(incident.id);
        this.deleteIncidentMedia(incident.id).subscribe();
        this.clearIncidentSelection();
        this.uiStore.setInteractedEntityId(incident.id, MainEntityType.incident);
      }),
      this.emitIncidentDiffAndUpdateLiveMap(),
      catchError(err => {
        this.updateIncidentLoader(false);
        return throwError(err);
      })
    );
  }

  showNotification(
    type: 'success' | 'failure',
    message: string,
    timeout: Partial<IndividualConfig> | undefined = undefined
  ) {
    type === 'success'
      ? this.alertService.success(this.translateService.instant(message), undefined, timeout, true)
      : this.alertService.error(this.translateService.instant(message), undefined, timeout, true);
  }

  setIncidentHasInsight(hasInsight) {
    this.hasInsight = hasInsight;
  }

  createIncident(incident: Partial<Incident & CreateIncidentMutationInput>): Observable<Incident> {
    this.updateIncidentLoader(true);

    const incidentMitigations: IncidentMitigationInput[] = [];
    incident.mitigations?.forEach(mitigation => {
      incidentMitigations.push({
        interval: mitigation.interval,
        mitigationTypeId: mitigation.mitigationType.id,
        unitId: mitigation.unitId, // What unit Id to use should we add userId also?
        driverId: mitigation.userId,
      });
    });

    const associatedUnits: IncidentUnitInput[] = [] as IncidentUnitInput[];

    incident.associatedUnits?.forEach(associatedUnit => {
      associatedUnits.push({
        driverId: associatedUnit.driverDetails?.userId,
        response: associatedUnit.unitResponse,
        unitId: associatedUnit.id,
      });
    });

    const address: AddressInput = {
      ...incident.address,
      ...{ point: incident.location },
    };

    const cameras: AssociatedCameraInput[] = [];

    if (incident.cameras) {
      incident.cameras.forEach(_camera => {
        cameras.push(asRoadEventCameraInput(_camera));
      });
    }

    const involvedVehicles: InvolvedVehicleInput[] = [];
    incident.involvedVehicles?.forEach(_vehicale => involvedVehicles.push(_vehicale));

    const additionalInfos: IncidentAdditionalInfoInput[] =
      (incident.additionalInfos as unknown as number[])?.map(info => ({
        additionalInfoId: info,
      })) || [];

    let reportSources: IncidentReportSourceInput[] = [];
    if (incident.reportSources) {
      if (Array.isArray(incident.reportSources)) {
        reportSources = incident.reportSources?.map(sources => ({
          reportSourceId: sources.id,
        }));
      } else {
        reportSources = [{ reportSourceId: incident.reportSources }];
      }
    }

    const newIncident: CreateIncidentMutationInput = {
      startedAt: incident.startedAt,
      address: address,
      affectedLanes: incident.affectedLanes,
      multiDirectionLanesAffected: incident.multiDirectionLanesAffected || false,
      allLanesAffected: incident.allLanesAffected || false,
      type: incident.type as IncidentType,
      subType: incident.subType,
      location: incident.location,
      status: incident.status as IncidentStatus,
      involvedVehicles: involvedVehicles,
      endedAt: incident.endedAt,
      typeDescription: incident.typeDescription,
      cameras: cameras,
      additionalInfos: additionalInfos,
      associatedUnits: associatedUnits,
      mitigations: incidentMitigations,
      notes: incident.notes,
      injuries: incident.injuries,
      estimatedEndTime: incident.estimatedEndTime
        ? {
            value: incident.estimatedEndTime,
          }
        : null,
      reportSources: reportSources,
      atmsId: incident.atmsId || null,
      cadId: incident.cadId || null,
      autoPublish: incident.autoPublish || false,
      responsePlan: incident.responsePlan,
      involvedVehiclesCount: incident.involvedVehiclesCount,
      severity: incident.severity,
      injurySeverities: incident.injurySeverities,
      attributes: incident.attributes,
      relatedEvents: incident.relatedEvents,
    };

    return this.incidentsApiService.createIncident(newIncident).pipe(
      map(res => {
        const workspaces = this.geoService.workspacesByLocation(incident.location.coordinates);
        return {
          ...res,
          ...{
            mitigatedAccounts: this._getIncidentMitigatedAccounts(res),
            featureType: res.type.toLocaleLowerCase(),
            featureSubTypeOf: MainEntityType.incident,
            workspaces,
          },
        };
      }),
      tap((res: Incident) => {
        this.uiStore.setInteractedEntityId(res.id, MainEntityType.incident);
        this.uploadFiles(res.id);
        this.clearIncidentSelection();
      }),
      this.emitIncidentDiffAndUpdateLiveMap(),
      catchError(err => {
        datadogLogs.logger.error('CREATE-INCIDENT-FAILED', {
          err: JSON.stringify(err),
          requestInput: JSON.stringify(newIncident),
          editIncidentStepTwoLocalStorage: JSON.stringify(
            this.localStorageService.get(LocalStorageKeys.EditIncidentStepTwo)
          ),
          editIncidentStepOneLocalStorage: JSON.stringify(
            this.localStorageService.get(LocalStorageKeys.EditIncidentStepOne)
          ),
        });
        setTimeout(() => this.showNotification('failure', 'notifications.failIncidentCreate', { timeOut: 5000 }));
        return throwError(err);
      }),
      finalize(() => {
        this.updateIncidentLoader(false);
      })
    );
  }

  camerasByPoint(coords: number[]): Promise<RoadEventCamera[]> {
    return new Promise(resolve => {
      resolve(
        this.camerasQuery.getCamerasByPoint(coords, 1000).map<RoadEventCamera>((camera, idx) => ({
          camera: camera,
          default: idx === 0,
          positionIndex: 0,
        }))
      );
    });
  }

  newIncident(coords: Array<number>, startFlow = true): Promise<Incident> {
    datadogLogs.removeLoggerGlobalContext('wc_incident_details');

    return new Promise((resolve, reject) => {
      if (!this.permissionsFacadeService.hasPermission('INCIDENT:CREATE')) {
        console.warn("You don't have the right permissions for creating a new incident.");
        reject();
        return;
      }

      this.updateIncidentLoader(true);
      const newIncident: Partial<Incident> = {
        affectedLanes: [],
        cameras: this.camerasQuery.getCamerasByPoint(coords, 1000).map<AssociatedCamera>((camera, idx) => ({
          camera: camera,
          default: idx === 0,
          positionIndex: 0,
        })),
        involvedVehicles: [],
        status: IncidentStatus.Confirmed,
        startedAt: new Date(),
        location: {
          coordinates: coords,
          type: 'Point',
        },
      };

      if (startFlow) {
        this.startCreateIncidentFlow();
      } else {
        newIncident.address = {
          point: {
            coordinates: coords,
            type: 'Point',
          },
        };
      }

      this.updateIncidentLoader(false);
      this.selectIncident(newIncident, false);
      resolve(newIncident as Incident);
    });
  }

  startCreateIncidentFlow() {
    this.uiStore.showCreateIncidentPanel = true;
  }

  addFilesToPendingDelete(incidentMediaId: number) {
    this._currentPendingDelete.push(incidentMediaId);
  }

  private deleteIncidentMedia(incidentId) {
    return this.incidentsApiService.deleteIncidentMedia({
      incidentId: incidentId,
      removed: this._currentPendingDelete,
    });
  }

  hasPendingMediaDeletions() {
    return this._currentPendingDelete.length > 0;
  }

  resetPendingMediaDeletions() {
    this._currentPendingDelete = [];
  }

  _getIncidentMitigatedAccounts(incident) {
    const mitigatedAccountIds: any[] = [];
    incident.mitigations?.map(val => {
      mitigatedAccountIds.push({ value: val.mitigation?.accountId });
    });
    return mitigatedAccountIds;
  }

  initEditExpiryTime(id: number) {
    const userLastEdit: LastActiveEditIncident = this.localStorageService.get(LocalStorageKeys.UserLastEdit);

    let waitForExpireWanningTime = WAIT_TIME_TO_SHOW_EXPIRE_WARNING_IN_MIN;
    let ExpireAfterWarningTime = EXPIRE_TIME_AFTER_WARNING_SHOW_IN_MIN;

    if (userLastEdit && userLastEdit.incidentId === id) {
      const timeDiffInMin = (new Date().getTime() - new Date(userLastEdit.timestamp).getTime()) / 60000;
      if (waitForExpireWanningTime + ExpireAfterWarningTime <= timeDiffInMin) {
        waitForExpireWanningTime = 0;
        ExpireAfterWarningTime = 0;
      } else if (timeDiffInMin <= waitForExpireWanningTime) {
        waitForExpireWanningTime = waitForExpireWanningTime - timeDiffInMin;
      } else {
        waitForExpireWanningTime = 0;
        ExpireAfterWarningTime = ExpireAfterWarningTime - (timeDiffInMin - WAIT_TIME_TO_SHOW_EXPIRE_WARNING_IN_MIN);
      }
    } else {
      const editTime: LastActiveEditIncident = {
        incidentId: id,
        timestamp: new Date(),
      };
      this.localStorageService.set(LocalStorageKeys.UserLastEdit, editTime);
    }

    const editAboutToExpire = timer(waitForExpireWanningTime * 60 * 1000).pipe(
      take(1),
      tap(() => this.confirmService.showConfirmDialog(ConfirmationModalType.EditAboutToExpire, ExpireAfterWarningTime))
    );
    const editExpired = timer(ExpireAfterWarningTime * 60 * 1000).pipe(
      take(1),
      tap(() => {
        this.confirmService.dialogRef?.close();
        datadogLogs.logger.info(`USER EDIT EXPIRED - incident ${id}`);
        this.confirmService.showConfirmDialog(ConfirmationModalType.EditTimeExpired, undefined, undefined, false);
        this.closeIncident();
      })
    );

    this.concatPreventEditTime = of(editAboutToExpire, editExpired).pipe(concatAll()).subscribe();
  }

  findIncidentByPointRadius(input: FindIncidentsInPointRadiusInput): Observable<RelatedIncident[]> {
    return this.incidentsApiService.findIncidentByPointRadius(input);
  }

  emitIncidentDiffAndUpdateLiveMap<T extends any>(): MonoTypeOperatorFunction<T> {
    return pipe(
      tap(incident =>
        this.entitiesService.emitNewEntitiesDiff({
          modified: {
            INCIDENT: [{ ...(incident as Record<string, unknown>), layerType: LayerType.Incident }],
          },
        })
      ),
      this.liveMapService.toggleWorkspaceVisibilityOnEntityUpdate(),
      this.liveMapService.setEntityLayerAsVisible(LayerType.Incident)
    );
  }

  isWrongWayAlert(incident: Incident): boolean {
    return (
      ![IncidentStatus.Completed, IncidentStatus.Rejected].includes(incident.status) &&
      !!this.incidentsQuery.ui.getEntity(incident.id)?.wrongWayAlert
    );
  }

  getUpdatedIncident(id: number) {
    this.loaderService.update({ eventViewPanel: true });

    return this.incidentsApiService.getIncident(id).pipe(
      tap((incident: Incident) => {
        this.loaderService.update({ eventViewPanel: false });
        this.emitIncidentDiffAndUpdateLiveMap();

        if (!incident) {
          throw new Error('no incident');
        }
      }),
      catchError(() => {
        this.loaderService.update({ eventViewPanel: false });
        this.router.navigate(['/live-map']);
        return EMPTY;
      })
    );
  }

  private wrongWayAlertsInitializer() {
    if (this.wrongWayAlertsActive) {
      this.incidentsQuery
        .selectAll()
        .pipe(
          map(incidents => {
            return incidents.filter(this.isNeedActionOrInProgress);
          }),
          map(incidents => {
            return incidents.map(({ id, type, externalId }) => ({
              id: id,
              wrongWayAlert: type === IncidentType.WrongWay,
              isFromFeed: !!externalId,
            }));
          })
        )
        .subscribe(incidentsUi => {
          this.entitiesService.emitNewUIDiff({
            INCIDENT: incidentsUi.map(({ id, wrongWayAlert }) => ({ id, wrongWayAlert })),
          });

          if (this.entitiesService.lastUpdatedAt === 0 || !Object.keys(this._cachedAllIncidentIds).length) {
            incidentsUi.forEach(({ id, wrongWayAlert }) => {
              this._cachedAllIncidentIds[id] = true;
              if (wrongWayAlert) this._cachedWrongWayAlertIncidentIds[id] = true;
            });

            return;
          }

          incidentsUi.forEach(({ id, wrongWayAlert, isFromFeed }) => {
            if (this._cachedAllIncidentIds[id]) return;

            if (!this._cachedWrongWayAlertIncidentIds[id] && wrongWayAlert && isFromFeed) {
              if (!this.wrongWayAlertPopupOpen) {
                this.showWrongWayDialog(id);
                this.playWrongWayAlertSound();

                this.wrongWayAlertPopupOpen = true;
              }

              this._cachedWrongWayAlertIncidentIds[id] = true;
            }

            this._cachedAllIncidentIds[id] = true;
          });
        });
    }
  }

  private updateIncidentLoader(state: boolean): void {
    this.loaderService.update({ incident: state });
    if (this.router.url.includes('view:incident')) {
      this.loaderService.update({ eventViewPanel: state });
    }
  }

  private handleExpireIncidentIndication(incidentId: number) {
    // Find incident in incidentsInProgressList
    const { interactedIncident, currentIndication } = this.getInteractedIncidentAndCurrentIndication(`${incidentId}`);
    if (
      currentIndication &&
      interactedIncident?.status === IncidentStatus.Confirmed &&
      interactedIncident.indications?.length
    ) {
      // Expire the next indication in 12 hours
      const TTL = 43200000;
      const expiryTime = new Date().getTime() + TTL;
      const indicationType = currentIndication;
      const indicationEpiryObj: IncidentIndicationExpiry = { id: +incidentId, expiryTime, indicationType };
      this.indicationExpiryList.push(indicationEpiryObj);
      this.localStorageService.set(LocalStorageKeys.IncidentIndicationsIds, this.indicationExpiryList);
    }
  }

  private uploadFiles(entityId) {
    if (!entityId) return;
    this.uploadService.addFilesToQueueAndUpload(UrlUploadEntityEnum.INCIDENT, entityId).subscribe();
  }

  private handleNeedActionIncidentsToPlaySoundAlert(incidentsNeedActionList: IncidentStoreEntity[]) {
    const needActionDiff = incidentsNeedActionList.filter(({ id }) => !this._cachedNeedActionIncidentIds[id]);
    const notWrongWayIncidents = needActionDiff.filter(
      ({ type }) => !this.wrongWayAlertsActive || type !== IncidentType.WrongWay
    );
    if (notWrongWayIncidents?.length) {
      this.sounds.playSound(AppSounds.incidentAlert, 1);
    }
    if (needActionDiff.length) {
      this.cacheIncidents(needActionDiff, this._cachedNeedActionIncidentIds);
    }
  }

  private handleIncidentIndicationList() {
    const localStorageList: IncidentIndicationExpiry[] = this.localStorageService.get(
      LocalStorageKeys.IncidentIndicationsIds
    );
    const currentTime = new Date().getTime();
    // Find re-enabled incident indication
    const incidentIndicationReEnabled = localStorageList?.find(
      incidentIndication => incidentIndication.expiryTime < currentTime
    );
    // Filter out the re-enabled indication and update lists
    if (incidentIndicationReEnabled) {
      const updatedIndicationsList = this.indicationExpiryList.filter(
        incidentIndication =>
          !(
            incidentIndication.id === incidentIndicationReEnabled?.id &&
            incidentIndication.indicationType === incidentIndicationReEnabled?.indicationType
          )
      );
      this.indicationExpiryList = updatedIndicationsList;
      this.localStorageService.set(LocalStorageKeys.IncidentIndicationsIds, this.indicationExpiryList);

      // Trigger view change in the relevant incident card
      this.indicationExpiryListChangeId$.next(incidentIndicationReEnabled?.id);
    }
  }

  private openCompleteRejectFormDialog(
    incident: Incident,
    status: IncidentStatus,
    closeIncident: boolean,
    isFromViewIncident: boolean
  ) {
    const dialogRef = this.dialog.open(CompleteRejectFormComponent, {
      width: '440px',
      height: 'auto',
      panelClass: 'mitigation-form-modal',
      data: {
        details: { incident: incident, isFromViewIncident: isFromViewIncident },
        hasActions: status === IncidentStatus.Rejected,
      },
    });

    if (closeIncident)
      dialogRef.afterClosed().subscribe(dialogConfirmed => {
        if (dialogConfirmed) {
          this.entitiesService.emitNewUIDiff({
            INCIDENT: [{ id: incident.id, wrongWayAlert: false }],
          });
          setTimeout(() => {
            this.closeIncident();
          }, 300);
        }
      });
  }

  private getInteractedIncidentAndCurrentIndication(incidentId: string): {
    interactedIncident: Partial<UIIncident & IncidentView> | undefined;
    currentIndication: IncidentIndicationType | undefined;
  } {
    return {
      interactedIncident: this.incidentsQuery.getEntity(+incidentId) as UIIncident & IncidentView,
      currentIndication: this.incidentsQuery.ui.getEntity(incidentId)?.currentIndication,
    };
  }

  private cacheIncidents(incidents: Incident[], cacheEntity: Record<string, boolean>): void {
    incidents.forEach(({ id }) => (cacheEntity[id] = true));
  }

  private showWrongWayDialog(incidentId: number) {
    this.confirmService.showConfirmDialog(
      ConfirmationModalType.WrongWayAlert,
      undefined,
      isConfirmed => {
        isConfirmed ? this.router.navigateByUrl(`live-map/(view:incident/${incidentId})`) : undefined;
        this.wrongWayAlertPopupOpen = false;
      },
      true
    );
  }

  private playWrongWayAlertSound() {
    const soundAlertMode = this.localStorageService.get(LocalStorageKeys.AllowSoundAlerts);
    if (soundAlertMode !== undefined && soundAlertMode !== SoundAlertMode.Off) {
      this.sounds.playSound(AppSounds.wrongWayAlert);
    }
  }

  private isNeedActionOrInProgress = (incident: IncidentStoreEntity) => {
    return [IncidentStatus.Unconfirmed, IncidentStatus.Confirmed].includes(incident.status);
  };
}
